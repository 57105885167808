import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import CE8Icon from '@material-ui/icons/NetworkCheck';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PublicSharpIcon from '@material-ui/icons/PublicSharp';
// import { findByLabelText } from '@testing-library/react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Title } from 'react-admin';

const useStyles = makeStyles({
    link: {
      textDecoration: 'none',
    },
    icon: {
      color: '#123212',
    },
    linkbox:{
        width:'100%',
        display: 'flex',
        justifyContent:'space-between',
    },
    table: {
      minWidth: '650px',
      marginTop:'6px',
      marginLeft: '10px',
      marginRight: '10px',
      maxWidth:'96%',
    },
    divStyle: {
      width:'100%',
      maxWidth: '1920px',
    },
    tableHead: {
      color:'#6c6c6c',
      background:'#e8e5e5',
      fontWeight:'600',
      lineHeight: '16px',
      fontSize:'14px',
      padding:'6px 24px 6px 16px',
    },
    boxshadowStyle:{
      boxShadow:'none',
    }
});


const CE8UrlField = () => {
  const classes = useStyles();
  return (
    <a href={'http://ce8.com/http/'}
      className={classes.link} target='_blank' rel='noopener noreferrer'
    >
      {/* {record[source]} */}
      <MyLocationIcon className={classes.icon} />
    </a>
  );
}

const ELKUrlField = () => {
  const classes = useStyles();
  return (
    <a href={'http://45.116.161.53:5601/app/kibana'}
      className={classes.link} target='_blank' rel='noopener noreferrer'
    >
      {/* {record[source]} */}
      <LocationOnIcon className={classes.icon} />
    </a>
  );
}

const ZabbixUrlField = () => {
  const classes = useStyles();
  return (
    <a href={'http://www.pchome.com.tw'}
      className={classes.link} target='_blank' rel='noopener noreferrer'
    >
      {/* {record[source]} */}
      <PublicSharpIcon className={classes.icon} />
    </a>
  );
}

const CactiUr1lField = () => {
  const classes = useStyles();
  return (
    <a href={'http://45.116.161.53:82/cacti'}
      className={classes.link} target='_blank' rel='noopener noreferrer'
    >
      {/* {record[source]} */}
      <CE8Icon className={classes.icon} />
    </a>
  );
}

function createData(name, calories) {
  return { name, calories};
}

const rows = [
  createData(<CE8UrlField label="ce8"/>, 'ce8'),
  createData(<ELKUrlField label="ELK"/>, 'ELK'),
  createData(<ZabbixUrlField label="Zabbix"/>, 'Zabbix'),
  createData(<CactiUr1lField label="cacti"/>, 'cacti'),
];

export const UrlList = props => {
  const classes = useStyles();
  return (
    <Card>
        <Title title={"App後台" + process.env.REACT_APP_SERVER_SUBTITLE + " - 常用連結"} />
        <CardContent className={classes.divStyle}>
          <TableContainer component={Paper} className={classes.boxshadowStyle}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" className={classes.tableHead}>名称</TableCell>
                  <TableCell className={classes.tableHead}>连结</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row,index) => (
                  <TableRow key={index}>
                    <TableCell align="left">{row.calories}</TableCell>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
    </Card>
  );
}