import { createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

export const myTheme = createMuiTheme({
    palette: {
        primary: {
            main:'#2196f3',
        },
        secondary: {
            main:'#42a5f5',
        },
        error: red,
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
    typography: {//text大小
        // Use the system font instead of the default Roboto font.
        fontSize: 14,
        fontFamily: [
            'Microsoft JhengHei',
            'Source Han Sans',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Arial',
            'sans-serif',
        ].join(','),
        button: {
            fontSize: '13px!important',
            transition:'all .3s ease-in',
        },      
    },
    overrides: {
        MuiButtonBase: { // override the styles of all instances of this component
            root: { // Name of the rule
                color: '#6c6c6c', // Some CSS
                fontSize: 14,
                fontFamily:['Microsoft JhengHei','Source Han Sans'],
                backgroundColor:'transparent',
            },
        },
        MuiButton:{
            textPrimary:{

            },
            containedPrimary:{
                backgroundColor: '#2cc9f4',
                boxShadow: 'none',
                border: '0',
                "&:hover":{
                    backgroundColor: '#16afd9',
                },
            },
        },
        MuiTypography:{
            h6:{
                color:'#fff!important',
            },
            body2:{
                fontSize:'13px',
                lineHeight: '16px',
            }
        },
        MuiTableCell:{
            root:{
                fontSize:'13px',
                lineHeight: '16px',
            },
            head:{
                fontSize:'14px',
                lineHeight: '16px',
                fontWeight:'600',
                fontWeight:'700',
                lineHeight: '16px',
                fontSize:'14px',
                padding:'6px 24px 6px 16px',
                color:'#6c6c6c',
            },
            footer:{
                borderBottom:'0',
            }
        },
        RaFilterForm:{
            form:{
                marginTop: '0',
                minHeight: '60px',
            },
        },
        MuiInputLabel:{
            root:{
                fontSize: '14px',  
            },
        },
        MuiInputBase:{
            root: { 
                background:'transparent!important',
                fontSize: '12px!important',
                "&:before":{
                    borderBottom:'1px solid #b8b8b8!important', 
                },
                "&:focus":{
                    outline:'none',
                },
            },
        },
        MuiIconButton:{
            root:{
                color: '#6c6c6c',
            },
            colorInherit:{
                // color:'#fff',
            },
            label:{
            //    color:'red'
            },
        },
        MuiSvgIcon:{
            root:{
                
            },
        },
        RaButton:{
            smallIcon:{
                width:'20px',
                height:'20px',
            },
        },
        RaDatagrid:{
            headerCell:{
                backgroundColor: '#e8e5e5',
            }
        },
        RaToolbar:{
            defaultToolbar:{
                justifyContent: 'flex-start',
            },
        },
        MuiFormLabel:{
            root:{
                fontWeight: '600',
            },
        },
        MuiFormControl:{
            root:{
                marginTop:'10px',
                marginBottom:'20px',
                "&:focus":{
                    outline:'none',
                },
            },
        },
        MuiAppBar:{ 
            colorPrimary:{
                boxShadow:'none',
            },
            colorSecondary:{
                backgroundImage:'linear-gradient(-20deg, #b721ff 0%, #21d4fd 100%);',
            },
            MuiIconButton: {
                colorInherit: {
                    // color: 'red'
                }
            },
            root:{
                '& > *':{
                    color:'#fff'
                }, 
            },     
        },
        MuiPaper:{
            elevation1:{
                boxShadow: '0 6px 15px rgba(36, 37, 38, 0.08)',
            },
        },
        MuiTabs:{
            root:{
                minHeight:'28px',
            },
            scroller:{
                backgroundColor: '#f2eeee',
                color:'#6c6c6c',
                paddingTop:'0px',
                paddingBottom:'0px',
                fontSize:'14px',
                fontWeight:'600',
            },
        },
        MuiTab:{
            root:{
                minHeight:'28px',
            },
            textColorInherit:{
                fontWeight:'600',
            },
        },
        MuiTableContainer:{
            root:{
                boxShadow:'none!important',
            },
        },
        MuiBox:{
            root:{
                padding:'0px',
            },
        },
        RaAppBar:{
            menuButtonIconOpen:{
                color:'#fff',
            },
        },
        MuiCheckbox:{
            colorPrimary:{
                color:'#6c6c6c!important',
            },
        },
        MuiTablePagination:{
            root:{
                color:'#6c6c6c!important',
            },
            caption:{
                color:'#8f8f8f!important',
            },
            selectRoot:{
                color:'#8f8f8f!important',
            },
            // "& > * ":{
            //     color:'#6c6c6c!important',
            // },
            menuItem:{
                color:'#6c6c6c!important',
            },
            // actions:{
            //     color:'#6c6c6c!important',
            // }
        },
    },
});


// export default myTheme;
