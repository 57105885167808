import CryptoJS from 'crypto-js';

const KEY = 'VKbSX8VYqGWGtZxmduQB9Ph6'; 

export function enDES3(str, key = KEY) {
    const encrypt = CryptoJS.TripleDES.encrypt(str, CryptoJS.enc.Utf8.parse(key), {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    let result = encrypt.toString();
    result = result.replace(/\//g, '_');
    return result;
  }
  
export function deDES3(str, key = KEY) {
    str = str.replace(/_/g, '/');
    const decrypt = CryptoJS.TripleDES.decrypt(str, CryptoJS.enc.Utf8.parse(key), {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    let result = decrypt.toString(CryptoJS.enc.Utf8);
    return result;
  }