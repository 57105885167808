import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { 
  List, Datagrid, TextField, Edit, Create, SimpleForm, TextInput,
  DeleteButton, EditButton, SelectInput
} from 'react-admin';

import CE8Icon from '@material-ui/icons/NetworkCheck';


const useStyles = makeStyles({
  link: {
    textDecoration: 'none',
  },
  icon: {
    color: '#123212',
  },
  tableHead: {
    color:'#6c6c6c',
    background:'#e8e5e5',
    fontWeight:'600',
    lineHeight: '16px',
    fontSize:'14px',
    padding:'6px 24px 6px 16px',
  },
});

const orders = [
  { _id: 1, order: '1' },
  { _id: 2, order: '2' },
  { _id: 3, order: '3' },
  { _id: 4, order: '4' },
  { _id: 5, order: '5' },
  { _id: 6, order: '6' },
  { _id: 7, order: '7' },
  { _id: 8, order: '8' },
  { _id: 9, order: '9' }
];

const CE8UrlField = ({ record = {}, source }) => {
  const classes = useStyles();
  return (
    <a href={'https://www.ce8.com/http/' + record[source]} 
      className={classes.link} target='_blank' rel='noopener noreferrer'
    >
      {/* {record[source]} */}
      <CE8Icon className={classes.icon} />
    </a>
  );
}

export const CDNList = props => {
  const classes = useStyles();
  return(
    <List
      {...props}
      title={"App後台" + process.env.REACT_APP_SERVER_SUBTITLE + " - AppCDN管理"}
      bulkActionButtons={false}
      perPage={10}
      sort={{ field: 'id', order: 'ASC' }}
    >
      <Datagrid optimized>
        <TextField label="順序" source="order" headerClassName={classes.tableHead}/>
        <TextField label="CDN" source="domain" headerClassName={classes.tableHead}/>
        <CE8UrlField label="ce8" source="domain" headerClassName={classes.tableHead}/>
        <EditButton headerClassName={classes.tableHead}/>
        <DeleteButton headerClassName={classes.tableHead}/>
      </Datagrid>
    </List>
  )
};

export const CDNEdit = props => (
  <Edit title={'編輯CDN'} {...props}>
    <SimpleForm>
      <SelectInput label="順序*" source="order" choices={orders} optionText="order" optionValue="_id" />
      <TextInput label="Domain*" source="domain" resettable />
    </SimpleForm>
  </Edit>
);


export const CDNCreate = props => (
  <Create title={'新建CDN'}  {...props}>
    <SimpleForm>
      <SelectInput label="順序*" source="order" choices={orders} optionText="order" optionValue="_id" />
      <TextInput label="Domain*" source="domain" />
    </SimpleForm>
  </Create>
);
