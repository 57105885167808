import { enDES3, deDES3 } from './encryption';
export default {
  // called when the user attempts to log in
  login: ({ username, password }) => {
     const request = new Request(process.env.REACT_APP_REST_PROVIDER + '/api/login', {
       method: 'POST',
       body: '{"body":"'+enDES3(JSON.stringify({ username, password }))+'"}',
       headers: new Headers({ 'Content-Type': 'application/json' }),
     });
     return fetch(request)
        .then(response => {
            if (response.status < 200 || response.status >= 300) {
              throw new Error(response.statusText);
            }
            return response.json();
        })
        .then(({ data }) => {
            let result = JSON.parse(deDES3(data))
            switch(result.state) {
              case '1':
                 localStorage.setItem('username', username);
                 localStorage.setItem('token', result.token);
                 break;
              case '2':
                 throw new Error('密碼錯誤');
                 break;
              case '3':
                 throw new Error('查無此帳號');
                 break;
              default:
                 throw new Error('內部錯誤');
            } 
        });
  },
  // called when the user clicks on the logout button
  logout: () => {
    let token = localStorage.getItem('token')
    if (null !== token){
      const request = new Request(process.env.REACT_APP_REST_PROVIDER + '/api/logout', {
        method: 'POST',
        body: JSON.stringify({}),
        headers: new Headers({ 'Content-Type': 'application/json','axto' : enDES3(token) }),
      });
      fetch(request)
          .then(response => {
              if (response.status < 200 || response.status >= 300) {
                alert('token錯誤');
              }
              return response.json();
          })
          .then(({ data }) => {
            let result = JSON.parse(deDES3(data))
              alert(result.msg);
          });
    }
    localStorage.removeItem('username');
    localStorage.removeItem('token');
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem('username');
      localStorage.removeItem('token');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    const request = new Request(process.env.REACT_APP_REST_PROVIDER + '/api/user', {
      method: 'get',
      headers: new Headers({ 'Content-Type': 'application/json','axto': enDES3(localStorage.getItem('token')) }),
    });
    return fetch(request)
       .then(response => {
           if (response.status < 200 || response.status >= 300) {
            localStorage.removeItem('username');
            localStorage.removeItem('token');
            return Promise.reject();
           }
           return Promise.resolve()
       }).catch((e)=>{ 
         //console.log(e);
         localStorage.removeItem('username');
         localStorage.removeItem('token');
         return Promise.reject();
      })
    
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => Promise.resolve(),
};
