import React from 'react';
import { 
  List, Datagrid, TextField, Edit, Create, SimpleForm, TextInput, 
  EditButton,Toolbar,SaveButton
} from 'react-admin';

const UserEditToolbar = props => (
  <Toolbar {...props} >
      <SaveButton />
  </Toolbar>
);

export const UserList = props => (
  <List
    {...props}
    title={"App後台" + process.env.REACT_APP_SERVER_SUBTITLE + " - User管理"}
    bulkActionButtons={false}
    perPage={10}
    sort={{ field: 'id', order: 'ASC' }}
  >
    <Datagrid rowClick="edit" optimized>
      <TextField label="UserName" source="username" />
      <EditButton />
    </Datagrid>
  </List>
);

export const UserEdit = props => (
  <Edit title={'編輯User'} {...props}>
    <SimpleForm toolbar={<UserEditToolbar />}>
      <TextInput label="UserName*" disabled source="username" />
      <TextInput label="Old_Password*"  type="password" source="old_password"/>
      <TextInput label="New_Password*"  type="password" source="password"/>
      <TextInput label="New_Password_confirm*"  type="password" source="password2"/>
    </SimpleForm>
  </Edit>
);

export const UserCreate = props => (
  <Create title={'新建User'}  {...props}>
    <SimpleForm>
      <TextInput label="UserName*" source="username"/>
      <TextInput label="Password*" type="password" source="password"/>
      <TextInput label="Password_confirm*" type="password" source="password2"/>
    </SimpleForm>
  </Create>
);
