import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { 
  List, Datagrid, TextField, Edit, SimpleForm, TextInput,
  SelectInput, ReferenceInput, Filter,
  EditButton, ReferenceField
  // Show, SimpleShowLayout, DateField,
} from 'react-admin';

const useStyles = makeStyles({
  tableHead: {
    color:'#6c6c6c',
    background:'#e8e5e5',
    fontWeight:'600',
    lineHeight: '16px',
    fontSize:'14px',
    padding:'6px 24px 6px 16px',
  },
});

const SSERVTitle = ({ record }) => {
  return <span>編輯SSERV - {record ? `${record.code}` : ''}</span>;
};

const DomainFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      label="平台"
      source="id"
      reference="api/tid"
      alwaysOn
      sort={{ field: 'tid', order: 'ASC' }}
      filterToQuery={searchText => ({ id: searchText })}
    >
      <SelectInput
        optionText="name"
        emptyText="-"
      />
    </ReferenceInput>
  </Filter>
);

export const SSERVList = props => {
  const classes = useStyles();
  return(
    <List
      {...props}
      title={"App後台" + process.env.REACT_APP_SERVER_SUBTITLE + " - SSERV熱更新"}
      bulkActionButtons={false}
      filters={<DomainFilter />}
      perPage={10}
      sort={{ field: 'tidx', order: 'ASC' }}
      exporter={false}
    >
      {/* <Datagrid optimized> */}
      <Datagrid rowClick="edit" optimized>
        <ReferenceField label="平台" source="tidx" reference="api/tid" link={false} headerClassName={classes.tableHead}>
          <TextField source="name" />
        </ReferenceField>
        <TextField label="代號" source="code" headerClassName={classes.tableHead}/>
        <TextField label="RN版本" source="app_version" headerClassName={classes.tableHead}/>
        <TextField label="SSERV版本" source="sserv" headerClassName={classes.tableHead}/>
        <TextField label="開網址版號" source="open_url_version" headerClassName={classes.tableHead}/>
        <TextField label="開網址URL" source="open_url" headerClassName={classes.tableHead}/>
        <EditButton headerClassName={classes.tableHead}/>
      </Datagrid>
    </List>
  )
};

export const SSERVEdit = props => (
  <Edit title={<SSERVTitle />} {...props}>
    <SimpleForm>
      <ReferenceField label="平台" source="tidx" reference="api/tid" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <TextField label="代號" source="code" />
      <TextField label="RN版本" source="app_version" />
      <TextInput label="SSERV版本*" source="sserv" resettable />
      <TextInput label="開網址版號" source="open_url_version" resettable />
      <TextInput label="開網址URL" source="open_url" resettable />
    </SimpleForm>
  </Edit>
);
