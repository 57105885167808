import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { 
  List, Datagrid, TextField, Edit, Create, SimpleForm, TextInput,
  DeleteButton, EditButton
} from 'react-admin';


const useStyles = makeStyles({
  tableHead: {
    color:'#6c6c6c',
    background:'#e8e5e5',
    fontWeight:'600',
    lineHeight: '16px',
    fontSize:'14px',
    padding:'6px 24px 6px 16px',
  },
});
// const ServerTitle = ({ record }) => {
//   return <span>編輯Web Server - {record ? `${record.code}` : ''}</span>;
// };

export const ServerList = props => {
  const classes = useStyles();
  return(
    <List
      {...props}
      title={"App後台" + process.env.REACT_APP_SERVER_SUBTITLE + " - Web Server管理"}
      bulkActionButtons={false}
      perPage={10}
      sort={{ field: 'id', order: 'ASC' }}
    >
      <Datagrid rowClick="edit" optimized>
        <TextField label="Server" source="server_name" headerClassName={classes.tableHead}/>
        <EditButton headerClassName={classes.tableHead}/>
        <DeleteButton headerClassName={classes.tableHead}/>
      </Datagrid>
    </List>
  )
};

export const ServerEdit = props => (
  <Edit title={'編輯Web Server'} {...props}>
    <SimpleForm>
      <TextInput label="Server*" source="server_name" resettable />
    </SimpleForm>
  </Edit>
);

export const ServerCreate = props => (
  <Create title={'新建Web Server'}  {...props}>
    <SimpleForm>
      <TextInput label="Server*" source="server_name" />
    </SimpleForm>
  </Create>
);
