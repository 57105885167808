import { fetchUtils } from 'react-admin';
import { enDES3, deDES3 } from '../encryption';

const apiUrl = process.env.REACT_APP_REST_PROVIDER;

const httpClient = (url, options = {}) => {
    if (!options.headers) {
      options.headers = new Headers({
        Accept: 'application/json'
      });
    }
    const axto = enDES3(localStorage.getItem('token'));
    options.headers.set('axto', axto);
    return fetchUtils.fetchJson(url, options);
};

export default {
    getList: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify(params.filter),
        };
        const url = `${apiUrl}/${resource}?data=`+encodeURIComponent(enDES3(JSON.stringify(query)));
        
        return httpClient(url).then(({ headers, json }) => ({
            data: JSON.parse(deDES3(json['data'])),
            total: parseInt(headers.get('content-range').split('/').pop(), 10),
        }));
    },

    getOne: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/`+encodeURIComponent(enDES3(JSON.stringify(params.id)))).then(({ json }) => ({
            data: JSON.parse(deDES3(json['data'])),
        })),

    getMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        const url = `${apiUrl}/${resource}?data=`+encodeURIComponent(enDES3(JSON.stringify(query)));
        return httpClient(url).then(({ json }) => ({ data: JSON.parse(deDES3(json['data'])) }));
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${apiUrl}/${resource}?data=`+encodeURIComponent(enDES3(JSON.stringify(query)));

        return httpClient(url).then(({ headers, json }) => ({
            data: JSON.parse(deDES3(json['data'])),
            total: parseInt(headers.get('content-range').split('/').pop(), 10),
        }));
    },

    update: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/`+encodeURIComponent(enDES3(JSON.stringify(params.id))), {
            method: 'PUT',
            body: '{"body":"'+enDES3(JSON.stringify(params.data))+'"}',
        }).then(({ json }) => ({ data: JSON.parse(deDES3(json['data'])) })),

    updateMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids}),
        };
        return httpClient(`${apiUrl}/${resource}?`+encodeURIComponent(enDES3(JSON.stringify(query))), {
            method: 'PUT',
            body: '{"body":"'+enDES3(JSON.stringify(params.data))+'"}',
        }).then(({ json }) => ({ data: JSON.parse(deDES3(json['data'])) }));
    },

    create: (resource, params) =>
        httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: '{"body":"'+enDES3(JSON.stringify(params.data))+'"}',
        }).then(({ json }) => ({
            data: { ...params.data, id: JSON.parse(deDES3(json['data'])).id },
        })),

    delete: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/`+encodeURIComponent(enDES3(JSON.stringify(params.id))), {
            method: 'DELETE',
        }).then(({ json }) => ({ data: JSON.parse(deDES3(json['data'])) })),

    deleteMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids}),
        };
        return httpClient(`${apiUrl}/${resource}?`+encodeURIComponent(enDES3(JSON.stringify(query))), {
            method: 'DELETE',
            body: '{"body":"'+enDES3(JSON.stringify(params.data))+'"}',
        }).then(({ json }) => ({ data: JSON.parse(deDES3(json['data'])) }));
    },
};