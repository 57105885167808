import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { 
  List, Datagrid, TextField, Edit, Create, SimpleForm, TextInput,
  SelectInput, ReferenceInput, EditButton, ReferenceField, Filter,
  DeleteButton
} from 'react-admin';

import LinkIcon from '@material-ui/icons/Public';
import CE8Icon from '@material-ui/icons/NetworkCheck';


const DomainTitle = ({ record }) => {
  return <span>域名 - {record ? `${record.domain}` : ''}</span>;
};

const orders = [
  { _id: 1, order: '1' },
  { _id: 2, order: '2' },
  { _id: 3, order: '3' },
  { _id: 4, order: '4' },
  { _id: 5, order: '5' },
  { _id: 6, order: '6' },
  { _id: 7, order: '7' },
  { _id: 8, order: '8' },
  { _id: 9, order: '9' }
];

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
  },
  icon: {
    color: '#123212',
  },
  tableHead: {
    color:'#6c6c6c',
    background:'#e8e5e5',
    fontWeight:'600',
    lineHeight: '16px',
    fontSize:'14px',
    padding:'6px 24px 6px 16px',
  },
  PrivateSwitchBase:{
    input:{
        color: '#6c6c6c!important',
    },
  },
}));

const WapUrlField = ({ record = {}, source }) => {
  const classes = useStyles();
  return (
    <a href={record[source]} className={classes.link} target='_blank' rel='noopener noreferrer'>
      {/* {record[source]} */}
      <LinkIcon className={classes.icon} />
    </a>
  );
}

const CE8UrlField = ({ record = {}, source }) => {
  const classes = useStyles();
  return (
    <a href={'https://www.ce8.com/http/' + record[source]} 
      className={classes.link} target='_blank' rel='noopener noreferrer'
    >
      {/* {record[source]} */}
      <CE8Icon className={classes.icon} />
    </a>
  );
}


const DomainFilter = (props) => (
  <Filter {...props}>
    {/* <TextInput label="平台" source="tid/v1" alwaysOn /> */}
    <ReferenceInput
      label="平台"
      source="id"
      reference="api/tid"
      alwaysOn
      sort={{ field: 'tid', order: 'ASC' }}
      filterToQuery={searchText => ({ id: searchText })}
    >
      <SelectInput
        optionText="name"
        emptyText="-"
        // translateChoice="false"
      />
    </ReferenceInput>
    {/* <TextInput source="tid" alwaysOn /> */}
  </Filter>
);

export const DomainList = props => {
  const classes = useStyles();
  return(
    <List
      {...props}
      title={"App後台" + process.env.REACT_APP_SERVER_SUBTITLE + " - AppAPIv1管理"}
      bulkActionButtons={false}
      filters={<DomainFilter />}
      perPage={10}
      sort={{ field: 'tid', order: 'ASC' }}
    >
      <Datagrid optimized>
        <ReferenceField label="平台" source="tidx" reference="api/tid" link={false} headerClassName={classes.tableHead}>
          <TextField source="name" /> 
        </ReferenceField>
        <TextField label="順序" source="order" headerClassName={classes.tableHead}/>
        <TextField label="APIv1" source="domain" headerClassName={classes.tableHead}/>
        <WapUrlField label="連結" source="domain" headerClassName={classes.tableHead}/>
        <CE8UrlField label="ce8" source="domain" headerClassName={classes.tableHead}/>
        <EditButton headerClassName={classes.tableHead}/>
        <DeleteButton headerClassName={classes.tableHead}/>
        
      </Datagrid>
    </List>
  )
}; 

export const DomainEdit = props => (
  <Edit title={<DomainTitle />} {...props}>
    <SimpleForm>
      {/* <TextInput disabled source="id" /> */}
      {/* <TextInput source="tid" /> */}
      <ReferenceInput label="平台" source="tidx" reference="api/tid">
        <SelectInput disabled optionText="name" />
      </ReferenceInput>
      <TextInput label="域名" source="domain" />
      {/* <NumberInput label="順序" source="order" /> */}
      <SelectInput label="順序" source="order" choices={orders} optionText="order" optionValue="_id" />
    </SimpleForm>
  </Edit>
);

export const DomainCreate = props => (
  <Create title={'新建域名'}  {...props}>
    <SimpleForm>
      <ReferenceInput label="平台" source="tidx" reference="api/tid" sortBy="tid">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput label="域名" source="domain" />
      {/* <NumberInput label="順序" source="order" /> */}
      <SelectInput label="順序" source="order" choices={orders} optionText="order" optionValue="_id" />
    </SimpleForm>
  </Create>
);
