import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { InputLabel, Select, MenuItem } from '@material-ui/core';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

import Paper from '@material-ui/core/Paper';
import { Title } from 'react-admin';
import MD5 from 'crypto-js/md5'
//时时彩分頁
let tab1 = [];
//快三分頁
let tab2 = [];
//六合彩分頁
let tab3 = [];
//北京赛车分頁
let tab4 = [];
//幸运飞艇分頁
let tab5 = [];
//11选5分頁
let tab6 = [];
//幸运28分頁
let tab7 = [];
//其他彩种分頁
let tab8 = [];
// 福體
let tab9 = [];
//取得彩種清單
getCollectionList();

//將彩種名稱顯示成中文名稱
function getCPName(code = ''){
  let result = ''
  const cpname = [
    { key: 'HUBK3', value: '湖北快3' },
    { key: 'XJSSC', value: '新疆时时彩' },
    { key: 'NIUBIK3', value: '快3' },
    { key: 'FC3D', value: '福彩3D' },
    { key: 'CQLHC', value: '重庆六合彩' },
    { key: 'BJK3', value: '北京快3' },
    { key: 'HK6', value: '香港六合彩' },
    { key: 'SH115', value: '上海11选5' },
    { key: 'BJXY28', value: '北京28' },
    { key: 'SD115', value: '山东11选5' },
    { key: 'GSK3', value: '甘肃快3' },
    { key: 'PC00', value: '加拿大西28' },
    { key: 'BG00', value: '台湾宾果28' },
    { key: 'GXK3', value: '广西快3' },
    { key: 'GZK3', value: '贵州快3' },
    { key: 'CQSSC', value: '重庆时时彩' },
    { key: 'XYFT', value: '幸运飞艇' },
    { key: 'JLK3', value: '吉林快3' },
    { key: 'BJKL8', value: '北京快乐8' },
    { key: 'TJSSC', value: '天津时时彩' },
    { key: 'MLAFT', value: '马耳他幸运飞艇' },
    { key: 'AHK3', value: '安徽快3' },
    { key: 'CAKENO', value: '加拿大卑斯快乐8' },
    { key: 'JSK3', value: '江苏快3' },
    { key: 'HN5FC', value: '河内5分彩' },
    { key: 'JND00', value: '加拿大28' },
    { key: 'HEBK3', value: '河北快3' },
    { key: 'SHK3', value: '上海快3' },
    { key: 'JX115', value: '江西11选5' },
    { key: 'PK10', value: '北京赛车' },
    { key: 'GD115', value: '广东11选5' },
    { key: 'MARKSIX', value: '六合彩' },
    { key: 'YNSSC', value: '印尼时时彩' },
    { key: 'D3', value: '福彩3D' },
    { key: 'AZSSC', value: '澳洲时时彩' },
    { key: 'PL3', value: '排列3' },
    { key: 'L1FT', value: '新幸运飞艇' },
    { key: 'WFLZ', value: '1分六合彩/澳门六合彩' },
    { key: 'NMCLZ', value: '新澳门六合彩' },
  ]

  cpname.forEach(item => {
    if(item['key'] === code){
      result = item['value']
    }
  })
  return result
}

const useStyles = makeStyles({
  table: {
    minWidth: '650px',
    marginTop:'6px',
    marginLeft: '10px',
    marginRight: '10px',
    maxWidth:'96%',
  },
  tableHead: {
    color:'#6c6c6c',
    background:'#fafafa',
    fontWeight:'600',
    lineHeight: '16px',
    fontSize:'14px',
    padding:'6px 24px 6px 16px',
  },
  boxshadowStyle:{
    // boxShadow:'none',
  },

});

//取得API簽名
function getSign(str='') {
  if (str===''){
    str = 'key=TJDLISRQ'
  }
  else{
    str = str+'&key=TJDLISRQ'
  }
  return MD5(str).toString()
}

//建置row資料
function createData(cpname, cpsource) {
  return {cpname, cpsource};
}

//取得彩種與採集源的列表
function getCollectionList() {
  //根據彩種名稱分配到各tab，找不到的放到tab9(其他彩種)
  let lottery_tab = [
      //tab1：時時彩
      { key: 'XJSSC',     value: 'tab1' },
      { key: 'CQSSC',     value: 'tab1' },
      { key: 'TJSSC',     value: 'tab1' },
      { key: 'YNSSC',     value: 'tab1' },
      { key: 'AZSSC',     value: 'tab1' },
      { key: 'HN5FC',     value: 'tab1' },
      //tab2：快三
      { key: 'HUBK3',     value: 'tab2' },
      { key: 'NIUBIK3',   value: 'tab2' },
      { key: 'BJK3',      value: 'tab2' },
      { key: 'GSK3',      value: 'tab2' },
      { key: 'GXK3',      value: 'tab2' },
      { key: 'GZK3',      value: 'tab2' },
      { key: 'JLK3',      value: 'tab2' },
      { key: 'HEBK3',     value: 'tab2' },
      { key: 'SHK3',      value: 'tab2' },
      { key: 'AHK3',      value: 'tab2' },
      { key: 'JSK3',      value: 'tab2' },
      //tab3：六合彩
      { key: 'HK6',       value: 'tab3' },
      { key: 'CQLHC',     value: 'tab3' },
      { key: 'MARKSIX',     value: 'tab3' },
      { key: 'WFLZ',     value: 'tab3' },
      { key: 'NMCLZ',     value: 'tab3' },
      //tab4：北京賽車
      { key: 'PK10',      value: 'tab4' },
      //tab5：幸運飛艇
      { key: 'XYFT',      value: 'tab5' },
      { key: 'MLAFT',     value: 'tab5' },
      { key: 'L1FT',     value: 'tab5' },
      //tab6：11選5
      { key: 'SH115',     value: 'tab6' },
      { key: 'SD115',     value: 'tab6' },
      { key: 'JX115',     value: 'tab6' },
      { key: 'GD115',     value: 'tab6' },
      //tab7：幸運28
      { key: 'JND00',     value: 'tab7' },
      { key: 'BJXY28',    value: 'tab7' },
      { key: 'PC00',      value: 'tab7' },
      { key: 'BG00',      value: 'tab7' },
      //tab9：福體
      { key: 'D3',        value: 'tab8' },
      { key: 'PL3',       value: 'tab8' },
  ];
  let sign = getSign();
  //console.log(process.env.REACT_APP_LOTTERY_SOURCE)
  fetch(process.env.REACT_APP_LOTTERY_SOURCE+'/thirdPartyLottery/everyThing/sign/'+sign,
    {
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, same-origin, *omit
      headers: {
        'content-type': 'application/json'
      },
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      redirect: 'follow', // manual, *follow, error
      referrer: 'no-referrer', // *client, no-referrer
    }
  )
  .then(response =>
    response.json().then(data => ({
       data: data,
       status: response.status
   })).then(res => {
       // console.log(res.status, res.data.result)
       let arrtab1 = [];
       let arrtab2 = [];
       let arrtab3 = [];
       let arrtab4 = [];
       let arrtab5 = [];
       let arrtab6 = [];
       let arrtab7 = [];
       let arrtab8 = [];
       let arrtab9 = [];
       for (const [key, value] of Object.entries(res.data.result)) {
          let search_flag = true
          lottery_tab.forEach(item => {
            if(item['key'] === key){
              search_flag = false
              switch (item['value']){
                case 'tab1':
                  arrtab1.push(createData(key, value));
                  break;
                case 'tab2':
                  arrtab2.push(createData(key, value));
                  break;
                case 'tab3':
                  arrtab3.push(createData(key, value));
                  break;
                case 'tab4':
                  arrtab4.push(createData(key, value));
                  break;
                case 'tab5':
                  arrtab5.push(createData(key, value));
                  break;
                case 'tab6':
                  arrtab6.push(createData(key, value));
                  break;
                case 'tab7':
                  arrtab7.push(createData(key, value));
                  break;
                case 'tab8':
                  arrtab8.push(createData(key, value));
                  break;
                default: // tab 9
                  break;
              }
            }
          });
          //在清單中找不到的放到其他彩種
          if(search_flag){
            arrtab9.push(createData(key, value))
          }
       }
       tab1 = arrtab1;
       tab2 = arrtab2;
       tab3 = arrtab3;
       tab4 = arrtab4;
       tab5 = arrtab5;
       tab6 = arrtab6;
       tab7 = arrtab7;
       tab8 = arrtab8;
       tab9 = arrtab9;
   })
 )
 .catch(function (error) {
   console.log('API error:'+error);
 });
};

//設置彩種play_name的採集源為source
function setSource(play_name, source) {
  let arr = [play_name, source];
  let str = arr.sort().toString().replace(',', '&')
  let sign = getSign(str);

  fetch(process.env.REACT_APP_LOTTERY_SOURCE+'/thirdPartyLottery/play_name/'+play_name+'/source/'+source+'/sign/'+sign,
    {
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, same-origin, *omit
      headers: {
        'content-type': 'application/json'
      },
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      redirect: 'follow', // manual, *follow, error
      referrer: 'no-referrer', // *client, no-referrer
    }
  )
  .then(response =>
    response.json().then(data => ({
       data: data,
       status: response.status
   })).then(res => {
      if(res.data.code === '0000'){
        alert('修改成功!')
      }else{
        alert(res.data.msg)
      }
      window.location.reload();
   })
  )
  .catch(function (error) {
    alert('修改失敗：'+error)
  });
};

//設置採集源的對話框
function setPlayNameSource(event,play_name) {
  let source = event.target.value
  if (window.confirm('確定要將'+play_name+'的採集源切換成'+source+'?')) {
    setSource(play_name, source);

  } else {
    window.location.reload();
  }
}

//分頁
function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}
const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const CollectionList = props => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setPage(0);
  };
  return (
    <div>
      <Card>
        <Title title={"App後台" + process.env.REACT_APP_SERVER_SUBTITLE + " - 採集源管理"} />
        <CardContent>
            <AppBar position="static">
              <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                <Tab label="時時彩" {...a11yProps(0)} />
                <Tab label="快三" {...a11yProps(1)} />
                <Tab label="六合彩" {...a11yProps(2)} />
                <Tab label="北京赛车" {...a11yProps(3)} />
                <Tab label="幸运飞艇" {...a11yProps(4)} />
                <Tab label="11选5" {...a11yProps(5)} />
                <Tab label="幸运28" {...a11yProps(6)} />
                <Tab label="福体" {...a11yProps(7)} />
                <Tab label="其他彩种" {...a11yProps(8)} />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              <TableContainer component={Paper} >
                <Table className={classes.table} aria-label="simple table" >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" className={classes.tableHead}>中文名称</TableCell>
                      <TableCell align="left" className={classes.tableHead}>彩种名称</TableCell>
                      <TableCell className={classes.tableHead}>採集来源</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {(rowsPerPage > 0
                      ? tab1.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : tab1
                    ).map((row) => (
                      <TableRow key={row.cpname}>
                        <TableCell component="th" scope="row">
                          {getCPName(row.cpname)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.cpname}
                        </TableCell>
                        <TableCell align="left">
                          <InputLabel id={'select_'+row.cpname}></InputLabel>
                          <Select id={'select_'+row.cpname} defaultValue={ (Object.keys(row.cpsource)[0] !== undefined) ? Object.keys(row.cpsource)[0] : "" } onChange={(event,child)=>{ setPlayNameSource(event,row.cpname) }}>
                            {(Object.keys(row.cpsource)[0] !== undefined) ?
                              row.cpsource[Object.keys(row.cpsource)[0]].map( (name, key) => {
                                  return <MenuItem  value={name} key={key}>{name}</MenuItem >;
                              })
                            : ""
                            }
                          </Select>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[10, 20, { label: 'All', value: -1 }]}
                        colSpan={3}
                        count={tab1.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: { 'aria-label': 'rows per page' },
                          native: true,
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                        labelRowsPerPage={'每页行数:'}
                      />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel value={value} index={1}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table" >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" className={classes.tableHead}>中文名称</TableCell>
                      <TableCell align="left" className={classes.tableHead}>彩种名称</TableCell>
                      <TableCell className={classes.tableHead}>採集来源</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {(rowsPerPage > 0
                      ? tab2.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : tab2
                    ).map((row) => (
                      <TableRow key={row.cpname}>
                        <TableCell component="th" scope="row">
                          {getCPName(row.cpname)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.cpname}
                        </TableCell>
                        <TableCell align="left">
                          <InputLabel id={'select_'+row.cpname}></InputLabel>
                          <Select id={'select_'+row.cpname} defaultValue={ (Object.keys(row.cpsource)[0] !== undefined) ? Object.keys(row.cpsource)[0] : "" } onChange={(event,child)=>{ setPlayNameSource(event,row.cpname) }}>
                            {(Object.keys(row.cpsource)[0] !== undefined) ?
                              row.cpsource[Object.keys(row.cpsource)[0]].map( (name, key) => {
                                  return <MenuItem  value={name} key={key}>{name}</MenuItem >;
                              })
                            : ""
                            }
                          </Select>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[10, 20, { label: 'All', value: -1 }]}
                        colSpan={3}
                        count={tab2.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: { 'aria-label': 'rows per page' },
                          native: true,
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                        labelRowsPerPage={'每页行数:'}
                      />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel value={value} index={2}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table" >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" className={classes.tableHead}>中文名称</TableCell>
                      <TableCell align="left" className={classes.tableHead}>彩种名称</TableCell>
                      <TableCell className={classes.tableHead}>採集来源</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {(rowsPerPage > 0
                      ? tab3.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : tab3
                    ).map((row) => (
                      <TableRow key={row.cpname}>
                        <TableCell component="th" scope="row">
                          {getCPName(row.cpname)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.cpname}
                        </TableCell>
                        <TableCell align="left">
                          <InputLabel id={'select_'+row.cpname}></InputLabel>
                          <Select id={'select_'+row.cpname} defaultValue={ (Object.keys(row.cpsource)[0] !== undefined) ? Object.keys(row.cpsource)[0] : "" } onChange={(event,child)=>{ setPlayNameSource(event,row.cpname) }}>
                            {(Object.keys(row.cpsource)[0] !== undefined) ?
                              row.cpsource[Object.keys(row.cpsource)[0]].map( (name, key) => {
                                  return <MenuItem  value={name} key={key}>{name}</MenuItem >;
                              })
                            : ""
                            }
                          </Select>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[10, 20, { label: 'All', value: -1 }]}
                        colSpan={3}
                        count={tab3.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: { 'aria-label': 'rows per page' },
                          native: true,
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                        labelRowsPerPage={'每页行数:'}
                      />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel value={value} index={3}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table" >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" className={classes.tableHead}>中文名称</TableCell>
                      <TableCell align="left" className={classes.tableHead}>彩种名称</TableCell>
                      <TableCell className={classes.tableHead}>採集来源</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {(rowsPerPage > 0
                      ? tab4.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : tab4
                    ).map((row) => (
                      <TableRow key={row.cpname}>
                        <TableCell component="th" scope="row">
                          {getCPName(row.cpname)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.cpname}
                        </TableCell>
                        <TableCell align="left">
                          <InputLabel id={'select_'+row.cpname}></InputLabel>
                          <Select id={'select_'+row.cpname} defaultValue={ (Object.keys(row.cpsource)[0] !== undefined) ? Object.keys(row.cpsource)[0] : "" } onChange={(event,child)=>{ setPlayNameSource(event,row.cpname) }}>
                            {(Object.keys(row.cpsource)[0] !== undefined) ?
                              row.cpsource[Object.keys(row.cpsource)[0]].map( (name, key) => {
                                  return <MenuItem  value={name} key={key}>{name}</MenuItem >;
                              })
                            : ""
                            }
                          </Select>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[10, 20, { label: 'All', value: -1 }]}
                        colSpan={3}
                        count={tab4.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: { 'aria-label': 'rows per page' },
                          native: true,
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                        labelRowsPerPage={'每页行数:'}
                      />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel value={value} index={4}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table" >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" className={classes.tableHead}>中文名称</TableCell>
                      <TableCell align="left" className={classes.tableHead}>彩种名称</TableCell>
                      <TableCell className={classes.tableHead}>採集来源</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {(rowsPerPage > 0
                      ? tab5.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : tab5
                    ).map((row) => (
                      <TableRow key={row.cpname}>
                        <TableCell component="th" scope="row">
                          {getCPName(row.cpname)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.cpname}
                        </TableCell>
                        <TableCell align="left">
                          <InputLabel id={'select_'+row.cpname}></InputLabel>
                          <Select id={'select_'+row.cpname} defaultValue={ (Object.keys(row.cpsource)[0] !== undefined) ? Object.keys(row.cpsource)[0] : "" } onChange={(event,child)=>{ setPlayNameSource(event,row.cpname) }}>
                            {(Object.keys(row.cpsource)[0] !== undefined) ?
                              row.cpsource[Object.keys(row.cpsource)[0]].map( (name, key) => {
                                  return <MenuItem  value={name} key={key}>{name}</MenuItem >;
                              })
                            : ""
                            }
                          </Select>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[10, 20, { label: 'All', value: -1 }]}
                        colSpan={3}
                        count={tab5.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: { 'aria-label': 'rows per page' },
                          native: true,
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                        labelRowsPerPage={'每页行数:'}
                      />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel value={value} index={5}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table" >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" className={classes.tableHead}>中文名称</TableCell>
                      <TableCell align="left" className={classes.tableHead}>彩种名称</TableCell>
                      <TableCell className={classes.tableHead}>採集来源</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {(rowsPerPage > 0
                      ? tab6.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : tab6
                    ).map((row) => (
                      <TableRow key={row.cpname}>
                        <TableCell component="th" scope="row">
                          {getCPName(row.cpname)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.cpname}
                        </TableCell>
                        <TableCell align="left">
                          <InputLabel id={'select_'+row.cpname}></InputLabel>
                          <Select id={'select_'+row.cpname} defaultValue={ (Object.keys(row.cpsource)[0] !== undefined) ? Object.keys(row.cpsource)[0] : "" } onChange={(event,child)=>{ setPlayNameSource(event,row.cpname) }}>
                            {(Object.keys(row.cpsource)[0] !== undefined) ?
                              row.cpsource[Object.keys(row.cpsource)[0]].map( (name, key) => {
                                  return <MenuItem  value={name} key={key}>{name}</MenuItem >;
                              })
                            : ""
                            }
                          </Select>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[10, 20, { label: 'All', value: -1 }]}
                        colSpan={3}
                        count={tab6.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: { 'aria-label': 'rows per page' },
                          native: true,
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                        labelRowsPerPage={'每页行数:'}
                      />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel value={value} index={6}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table" >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" className={classes.tableHead}>中文名称</TableCell>
                      <TableCell align="left" className={classes.tableHead}>彩种名称</TableCell>
                      <TableCell className={classes.tableHead}>採集来源</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {(rowsPerPage > 0
                      ? tab7.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : tab7
                    ).map((row) => (
                      <TableRow key={row.cpname}>
                        <TableCell component="th" scope="row">
                          {getCPName(row.cpname)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.cpname}
                        </TableCell>
                        <TableCell align="left">
                          <InputLabel id={'select_'+row.cpname}></InputLabel>
                          <Select id={'select_'+row.cpname} defaultValue={ (Object.keys(row.cpsource)[0] !== undefined) ? Object.keys(row.cpsource)[0] : "" } onChange={(event,child)=>{ setPlayNameSource(event,row.cpname) }}>
                            {(Object.keys(row.cpsource)[0] !== undefined) ?
                              row.cpsource[Object.keys(row.cpsource)[0]].map( (name, key) => {
                                  return <MenuItem  value={name} key={key}>{name}</MenuItem >;
                              })
                            : ""
                            }
                          </Select>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[10, 20, { label: 'All', value: -1 }]}
                        colSpan={3}
                        count={tab7.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: { 'aria-label': 'rows per page' },
                          native: true,
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                        labelRowsPerPage={'每页行数:'}
                      />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel value={value} index={7}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table" >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" className={classes.tableHead}>中文名称</TableCell>
                      <TableCell align="left" className={classes.tableHead}>彩种名称</TableCell>
                      <TableCell className={classes.tableHead}>採集来源</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {(rowsPerPage > 0
                      ? tab8.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : tab8
                    ).map((row) => (
                      <TableRow key={row.cpname}>
                        <TableCell component="th" scope="row">
                          {getCPName(row.cpname)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.cpname}
                        </TableCell>
                        <TableCell align="left">
                          <InputLabel id={'select_'+row.cpname}></InputLabel>
                          <Select id={'select_'+row.cpname} defaultValue={ (Object.keys(row.cpsource)[0] !== undefined) ? Object.keys(row.cpsource)[0] : "" } onChange={(event,child)=>{ setPlayNameSource(event,row.cpname) }}>
                            {(Object.keys(row.cpsource)[0] !== undefined) ?
                              row.cpsource[Object.keys(row.cpsource)[0]].map( (name, key) => {
                                  return <MenuItem  value={name} key={key}>{name}</MenuItem >;
                              })
                            : ""
                            }
                          </Select>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[10, 20, { label: 'All', value: -1 }]}
                        colSpan={3}
                        count={tab8.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: { 'aria-label': 'rows per page' },
                          native: true,
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                        labelRowsPerPage={'每页行数:'}
                      />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel value={value} index={8}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table" >
                <TableHead>
                  <TableRow>
                    <TableCell align="left" className={classes.tableHead}>中文名称</TableCell>
                    <TableCell align="left" className={classes.tableHead}>彩种名称</TableCell>
                    <TableCell className={classes.tableHead}>採集来源</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                      ? tab9.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : tab9
                  ).map((row) => (
                    <TableRow key={row.cpname}>
                      <TableCell component="th" scope="row">
                        {getCPName(row.cpname)}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.cpname}
                      </TableCell>
                      <TableCell align="left">
                        <InputLabel id={'select_'+row.cpname}></InputLabel>
                        <Select id={'select_'+row.cpname} defaultValue={ (Object.keys(row.cpsource)[0] !== undefined) ? Object.keys(row.cpsource)[0] : "" } onChange={(event,child)=>{ setPlayNameSource(event,row.cpname) }}>
                          {(Object.keys(row.cpsource)[0] !== undefined) ?
                            row.cpsource[Object.keys(row.cpsource)[0]].map( (name, key) => {
                              return <MenuItem  value={name} key={key}>{name}</MenuItem >;
                            })
                            : ""
                          }
                        </Select>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[10, 20, { label: 'All', value: -1 }]}
                      colSpan={3}
                      count={tab9.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: { 'aria-label': 'rows per page' },
                        native: true,
                      }}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                      labelRowsPerPage={'每页行数:'}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </TabPanel>
        </CardContent>
      </Card>
    </div>
  );
}
