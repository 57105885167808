import React from 'react';
import { Route } from 'react-router-dom';
//import Links from './Links';
//import collection from './collection';
//import collectionModify from './collectionModify';

export default [
    //<Route exact path="/Links" component={Links} />,
    //<Route exact path="/collection" component={collection} />,
    //<Route exact path="/collectionModify" component={collectionModify} />,
];