import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { 
  List, Datagrid, TextField, Edit, Create, SimpleForm, TextInput,
  SelectInput, ReferenceInput, Filter,
  DeleteButton, EditButton, ReferenceField
  // Show, SimpleShowLayout, DateField,
} from 'react-admin';
// import RichTextInput from 'ra-input-rich-text';

const useStyles = makeStyles({
  tableHead: {
    color:'#6c6c6c',
    background:'#e8e5e5',
    fontWeight:'600',
    lineHeight: '16px',
    fontSize:'14px',
    padding:'6px 24px 6px 16px',
  },
});
const AppTitle = ({ record }) => {
  return <span>編輯App - {record ? `${record.code}` : ''}</span>;
};

const DomainFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      label="平台"
      source="id"
      reference="api/tid"
      alwaysOn
      sort={{ field: 'tid', order: 'ASC' }}
      filterToQuery={searchText => ({ id: searchText })}
    >
      <SelectInput
        optionText="name"
        emptyText="-"
      />
    </ReferenceInput>
  </Filter>
);

export const AppList = props => {
  const classes = useStyles();
  return(
    <List
      {...props}
      title={"App後台" + process.env.REACT_APP_SERVER_SUBTITLE + " - App下載信息管理"}
      bulkActionButtons={false}
      filters={<DomainFilter />}
      perPage={10}
      sort={{ field: 'tidx', order: 'ASC' }}
    >
      {/* <Datagrid optimized> */}
      <Datagrid rowClick="edit" optimized>
        {/* <ReferenceField label="平台" source="tid" reference="api/tid_query" link={false}> */}
        <ReferenceField label="平台" source="tidx" reference="api/tid" link={false} headerClassName={classes.tableHead}>
          <TextField source="name" />
        </ReferenceField>
        <TextField label="代號" source="code" headerClassName={classes.tableHead}/>
        <TextField label="版本" source="app_version" headerClassName={classes.tableHead}/>
        <TextField label="ipa下載連結" source="ipa_uri" headerClassName={classes.tableHead}/>
        <EditButton headerClassName={classes.tableHead}/>
        <DeleteButton headerClassName={classes.tableHead}/>
      </Datagrid>
    </List>
  )
};

export const AppEdit = props => (
  <Edit title={<AppTitle />} {...props}>
    <SimpleForm>
      <ReferenceInput label="平台" source="tidx" reference="api/tid">
        <SelectInput disabled optionText="name" />
      </ReferenceInput>
      <TextInput label="代號*" source="code" resettable />
      <TextInput label="版本*" source="app_version" resettable />
      <TextInput label="大小*" source="apk_size" resettable />
      <TextInput label="ipa下載連結" source="ipa_uri" type="url" resettable fullWidth />
      <TextInput label="新功能" source="feature" multiline fullWidth />
    </SimpleForm>
  </Edit>
);

export const AppCreate = props => (
  <Create title={'新建App'}  {...props}>
    <SimpleForm>
      <ReferenceInput label="平台" source="tidx" reference="api/tid" sortBy="id">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput label="代號*" source="code" />
      <TextInput label="版本*" source="app_version" />
      <TextInput label="大小*" source="apk_size" />
      <TextInput label="ipa下載連結" source="ipa_uri" type="url" />
      <TextInput label="新功能" source="feature" />
    </SimpleForm>
  </Create>
);
