import domainDataProvider from './domainDataProvider';
import phpDataProvider from './phpDataProvider';
const python_list = [  'api/domains',
                       'api/cdns',
                       'api/tid',
                       'api/apps',
                       'ief9ocun',
                       'lslbhzlu',
                       '7vcksb5v',
                       'api/user',
                    ]

const php_list = [  //'api/v1/posts',
                 ]

const restProvider = {
  getList: (resource, params) => {
    return selectApiServer(resource, params, 'getList');
  },
  getOne: (resource, params) => {
    return selectApiServer(resource, params, 'getOne');
  },
  getMany: (resource, params) => {
    return selectApiServer(resource, params, 'getMany');
  },
  getManyReference: (resource, params) => {
    return selectApiServer(resource, params, 'getManyReference');
  },
  update: (resource, params) => {
    return selectApiServer(resource, params, 'update');
  },
  updateMany: (resource, params) => {
    return selectApiServer(resource, params, 'updateMany');
  },
  create: (resource, params) => {
    return selectApiServer(resource, params, 'create');
  },
  delete: (resource, params) => {
    return selectApiServer(resource, params, 'delete');
  },
  deleteMany: (resource, params) => {
    return selectApiServer(resource, params, 'deleteMany');
  }
};

function selectApiServer(resource,params,method='getList'){
  //pythons api server
  if (python_list.includes(resource)) {
    return domainDataProvider[method](resource, params)
  }
  //php api server
  if (php_list.includes(resource)) {
    return phpDataProvider[method](resource, params)
  }
}

export default restProvider;