import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { 
  List, Datagrid, TextField, Edit, Create, SimpleForm, TextInput,
  EditButton, ReferenceField, DeleteButton, ReferenceInput, SelectInput
} from 'react-admin';

const useStyles = makeStyles({
  tableHead: {
    color:'#6c6c6c',
    background:'#e8e5e5',
    fontWeight:'600',
    lineHeight: '16px',
    fontSize:'14px',
    padding:'6px 24px 6px 16px',
  },
});
// const CDNUrlTitle = ({ record }) => {
//   return <span>編輯CDN Url - {record ? `${record.code}` : ''}</span>;
// };

export const CDNUrlList = props => {
  const classes = useStyles();
  return(
    <List
      {...props}
      title={"App後台" + process.env.REACT_APP_SERVER_SUBTITLE + " - CDN Url管理"}
      bulkActionButtons={false}
      perPage={10}
      sort={{ field: 'id', order: 'ASC' }}
      exporter={false}
    >
      <Datagrid rowClick="edit" optimized>
        <ReferenceField label="Server" source="server_id" reference="lslbhzlu" link={false} headerClassName={classes.tableHead}>
          <TextField source="server_name" />
        </ReferenceField>
        <TextField label="CDN Url" source="cdn" headerClassName={classes.tableHead}/>
        <TextField label="Api Url" source="api" headerClassName={classes.tableHead}/>
        <EditButton headerClassName={classes.tableHead}/>
        <DeleteButton headerClassName={classes.tableHead}/>
      </Datagrid>
    </List>
  )
};

export const CDNUrlEdit = props => (
  <Edit title={'編輯CDN Url'} {...props}>
    <SimpleForm>
      <ReferenceInput label="Server" source="server_id" reference="lslbhzlu">
        <SelectInput disabled optionText="server_name" />
      </ReferenceInput>
      <TextInput label="CDN Url" source="cdn" />
      <TextInput label="Api Url" source="api" />
    </SimpleForm>
  </Edit>
);

export const CDNUrlCreate = props => (
  <Create title={'新建CDN Url'}  {...props}>
    <SimpleForm>
      <ReferenceInput label="Server" source="server_id" reference="lslbhzlu">
        <SelectInput optionText="server_name" />
      </ReferenceInput>
      <TextInput label="CDN Url" source="cdn" />
      <TextInput label="Api Url" source="api" />
    </SimpleForm>
  </Create>
);
