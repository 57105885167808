import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  List, Datagrid, TextField, Edit, Create, SimpleForm, TextInput,
  EditButton, DeleteButton, NumberInput
} from 'react-admin';

// import StatusIcon from '@material-ui/icons/Public';


const RecordTitle = ({ record }) => {
  return <span>平台 - {record ? `${record.name}` : ''}</span>;
};

const useStyles = makeStyles({
  tableHead: {
    color:'#6c6c6c',
    background:'#e8e5e5',
    fontWeight:'600',
    lineHeight: '16px',
    fontSize:'14px',
    padding:'6px 24px 6px 16px',
  },
});

// const StatusUrlField = ({ record = {}, source }) => {
//   const classes = useStyles();
//   return (
//     <a href={record[source]} className={classes.link} target='_blank' rel='noopener noreferrer'>
//       {/* {record[source]} */}
//       <StatusIcon className={classes.icon} />
//     </a>
//   );
// }

export const TidList = props => {
  const classes = useStyles();
  return(
    <List
    {...props}
    title={"App後台" + process.env.REACT_APP_SERVER_SUBTITLE + " - 平台名稱管理"}
    bulkActionButtons={false}
  >
    <Datagrid rowClick="edit">
      {/* <TextField source="id" /> */}
      <TextField source="tid" headerClassName={classes.tableHead}/>
      <TextField label="tidx" source="id" headerClassName={classes.tableHead}/>
      <TextField label="名稱" source="name" headerClassName={classes.tableHead}/>
      {/* <StatusUrlField label="App更新狀態" source="name" /> */}
      <EditButton headerClassName={classes.tableHead}/>
      <DeleteButton headerClassName={classes.tableHead}/>
    </Datagrid>
  </List>
  )
  
};

export const TidEdit = props => {
  return(
    <Edit title={<RecordTitle />} {...props}>
    <SimpleForm>
      {/* <TextInput disabled source="id" /> */}
      <NumberInput disabled source="tid" />
      <TextInput label="平台" source="name" />
    </SimpleForm>
  </Edit>
  ) 
};

export const TidCreate= props => (
  <Create title={'新建平台'}  {...props}>
    <SimpleForm>
      <NumberInput source="tid" />
      <TextInput label="名稱" source="name" />
    </SimpleForm>
  </Create>
);
