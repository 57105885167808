import React from 'react';
import { Admin, Resource, Login } from 'react-admin';
import dataProviders from './service/dataProviders';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import chineseTWMessages from 'ra-language-chinese-traditional';

import authProvider from './authProvider';
import { DomainList, DomainEdit, DomainCreate } from './domains';
import { CDNList,CDNEdit,CDNCreate } from './cdns';
import { CollectionList } from './collection';
import { TidList, TidEdit, TidCreate } from './tids';
import { AppList, AppEdit, AppCreate } from './apps';
import { SSERVList, SSERVEdit } from './sservs';
import { ServerList, ServerEdit, ServerCreate } from './servers';
import { CDNUrlList, CDNUrlEdit, CDNUrlCreate } from './cdnurl';
import { UrlList } from './Links';
import { UserList, UserEdit, UserCreate } from './user';
// import { AppShow } from './apps';

import DomainIcon from '@material-ui/icons/CollectionsBookmark';
import CDNIcon from '@material-ui/icons/Cloud';
import FolderSpecialIcon from '@material-ui/icons/FolderSpecial';
//import BusinessIcon from '@material-ui/icons/Business';

import TidIcon from '@material-ui/icons/LocationCity';
import AppIcon from '@material-ui/icons/Smartphone';
import SSERVIcon from '@material-ui/icons/PresentToAll';
import ServerIcon from '@material-ui/icons/Dns';
import CDNUrlIcon from '@material-ui/icons/SettingsSystemDaydream';

import './App.css';
import { myTheme } from './theme';
import customRoutes from './customRoutes';
import UserIcon from '@material-ui/icons/AccountBox';


const i18nProvider = polyglotI18nProvider(() => chineseTWMessages, 'zh-tw');
// const messages = { 'zh-tw': chineseTWMessages };

/*
const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({
      Accept: 'application/json'
    });
  }
  // const token = localStorage.getItem('token');
  // options.headers.set('Authorization', `Bearer ${token}`);
  const axto = localStorage.getItem('token');
  options.headers.set('axto', axto);
  return fetchUtils.fetchJson(url, options);
};
*/

const LoginPage = () => (
  <Login
    backgroundImage=""
  />
);

const App = () => (
  <Admin
    title="App後台"
    loginPage={LoginPage}
    dataProvider={dataProviders}
    authProvider={authProvider}
    i18nProvider={i18nProvider}
    customRoutes={customRoutes}
    theme={myTheme}
    // locale="zh-tw"
    // messages={messages}
  >
    {/*<Dashboard/>*/}
    <Resource name="api/domains" options={{ label: 'AppAPIv1管理' }} list={DomainList} edit={DomainEdit} create={DomainCreate} icon={DomainIcon} />
    <Resource name="api/cdns" options={{ label: 'AppCDN管理' }} list={CDNList} edit={CDNEdit} create={CDNCreate} icon={CDNIcon} />
    <Resource name="api/tid" options={{ label: '平台名稱管理' }} list={TidList} edit={TidEdit} create={TidCreate} icon={TidIcon} />
    <Resource name="api/apps" options={{ label: 'App下載信息管理' }} list={AppList} edit={AppEdit} create={AppCreate} icon={AppIcon} />
    <Resource name="ief9ocun" options={{ label: 'SSERV熱更新' }} list={SSERVList} edit={SSERVEdit} icon={SSERVIcon} />
    <Resource name="lslbhzlu" options={{ label: 'Web Server管理' }} list={ServerList} edit={ServerEdit} create={ServerCreate} icon={ServerIcon} />
    <Resource name="7vcksb5v" options={{ label: '網站CDN Url管理' }} list={CDNUrlList} edit={CDNUrlEdit} create={CDNUrlCreate} icon={CDNUrlIcon} />
    <Resource name="collection" options={{ label: '採集源管理' }} list={CollectionList}  icon={FolderSpecialIcon} />
    <Resource name="api/user" options={{ label: 'User管理' }} list={UserList} edit={UserEdit} create={UserCreate} icon={UserIcon} />
    <Resource name="Links" options={{ label: '常用連結' }} list={UrlList} icon={DomainIcon} />
    {/*<Resource name="collectionModify" options={{ label: '採集源維護' }} list={UrlList}  icon={BusinessIcon} />*/}
  </Admin>
);
  

// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

export default App;
